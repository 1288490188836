import { Sandpack } from "@codesandbox/sandpack-react";
import { neoCyan } from "@codesandbox/sandpack-themes";

function App() {
  const files = {}

  return (
   <Sandpack
    file = {files}
    theme = {neoCyan}
    template = "vite-vue"
    options={{
      showConsoleButton: true,
      showInlineErrors: true,
      showNavigator: true,
      showLineNumbers: true,
      showTabs: true,
    }}
   />
  )
}

export default App;
